













import { Vue, Component, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

@Component({
  components: { FscSimpleCard },
})
export default class ReportSlotsCard extends Vue {
  public name = "ReportSlotsCard";

  @Prop({ type: String })
  public label!: string;

  @Prop({ type: String })
  public type!: string;

  @Prop({ type: String })
  public typeClass!: string;
}
