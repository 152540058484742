















import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import ActionButton from "@/components/Button/ActionButton.vue";
import { namespace } from "vuex-class";

const StudentModule = namespace("student");
@Component({
  components: {
    Table,
    ActionButton,
  },
})
export default class DashboardLivePayMessages extends Vue {
  public name = "DashboardLivePayMessages";

  @Prop()
  livePayMessages!: any;

  @StudentModule.Mutation("SET_SELECTED_ROW_NAME")
  protected setSelectedRowName!: any;

  public fields = [
    {
      key: "createdOn",
      label: "Datum",
      sortable: false,
    },
    {
      key: "studentName",
      label: "Kunde",
      sortable: false,
    },
    {
      key: "amount",
      label: "Betrag",
      sortable: false,
    },
    {
      key: "message",
      label: "Grund",
      sortable: false,
    },
    {
      key: "studentEducationId",
      label: "",
      sortable: false,
    },
  ];

  public onServicesRedirect(item: any) {
    const id = item.studentEducationId;
    this.setSelectedRowName(item.studentName);
    this.$router.push({
      name: "EducationEventList",
      params: { id: id },
    });
  }
}
