









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SaveButton extends Vue {
  public name = "SaveButton";

  @Prop({ default: () => "Speichern", type: String })
  public label!: string;

  @Prop({ default: () => false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: () => "check", type: String })
  public icon!: boolean;

  @Prop({ default: () => null, type: Boolean })
  public loading!: boolean;

  private onClick() {
    if (this.loading) return;
    this.$emit("click");
  }
}
