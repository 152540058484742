















import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DashboardCalendar extends Vue {
  public name = "DashboardCalendar";
}
