























import { Vue, Component } from "vue-property-decorator";
import ReportSlotsCard from "@/views/Report/ReportSlotsCard.vue";
import UserService from "@/services/UserService";
import moment from "moment";
import AuthImage from "@/directives/AuthImage";

const USER_URL = "users";

@Component({
  components: { ReportSlotsCard },
  directives: {
    AuthImage: new AuthImage(),
  },
})
export default class DashboardProfileSection extends Vue {
  public name = "DashboardProfileSection";

  public salutation = "";

  private get user() {
    return UserService.getUser();
  }

  private get userImageUrl() {
    return `/${USER_URL}/avatar?userId=${this.user.id}`;
  }

  private get logoImageUrl() {
    return `/driving-school-documents/logo/current?userId=${this.user.id}`;
  }

  public created() {
    const time = moment();
    const morning = moment({ hour: 5, minute: 0, seconds: 0 });
    const afternoon = moment({ hour: 12, minute: 0, seconds: 0 });
    const evening = moment({ hour: 18, minute: 0, seconds: 0 });
    this.salutation =
      time.isBefore(morning) || time.isAfter(evening)
        ? "dashboard.salutation_evening"
        : time.isBefore(afternoon)
        ? "dashboard.salutation_morning"
        : "dashboard.salutation_afternoon";
  }

  public get userFullName() {
    if (!this.user) return "";
    return this.user?.firstName || "";
  }
}
