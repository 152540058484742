import axios, { cacheableAxios } from "@/utils/axios";

if (!axios) {
  throw new Error("[vue-auth-image] cannot locate Axios");
}

async function setImgSrc(el: HTMLElement, binding: any, vNode: any) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    const imageUrl = binding.value;

    if (vNode.data.attrs?.nofetch || !imageUrl) {
      return;
    }

    (await cacheableAxios())({
      method: "get",
      url: imageUrl,
      responseType: "arraybuffer",
    })
      .then(function (response: any) {
        el.setAttribute("valid", "true");
        const mimeType = response.headers["content-type"].toLowerCase();

        // @ts-ignore
        const imgBase64 = new Buffer(response.data, "binary").toString("base64");

        const imgSrc = "data:" + mimeType + ";base64," + imgBase64;

        if (!vNode.data.attrs?.customsrc) {
          el.setAttribute("src", imgSrc);
        }

        if (binding.expression && vNode.context[binding.expression]) {
          const success: string | null = el.getAttribute("success");
          if (success && typeof vNode.context[success] == "function") {
            vNode.context[success](imgSrc);
          }
        }
      })
      .catch(function (error: any) {
        // emit hasError event
        el.dispatchEvent(new Event("hasError"));
        el.setAttribute("valid", "false");

        if (vNode.data.attrs?.failedClass) {
          el.classList.add(vNode.data.attrs.failedClass);
        }

        if (binding.expression && vNode.context[binding.expression]) {
          const fail: string | null = el.getAttribute("fail");
          if (fail && typeof vNode.context[fail] == "function") {
            vNode.context[fail]();
          }
        }
        el.setAttribute("src", "");
      });
  }
}

export default class AuthImage {
  public bind(el: HTMLElement, binding: any, vNode: any) {
    setImgSrc(el, binding, vNode);
  }

  public componentUpdated(el: HTMLElement, binding: any, vNode: any) {
    setImgSrc(el, binding, vNode);
  }
}
