













































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import ActionButton from "@/components/Button/ActionButton.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import { namespace } from "vuex-class";

const WarningsModule = namespace("warnings");

@Component({
  components: {
    FscModal,
    Table,
    ActionButton,
  },
})
export default class DashboardWarningMessages extends Vue {
  public name = "DashboardWarningMessages";

  @Prop()
  warningMessages!: any;

  @Prop({ default: () => 0 })
  totalRows!: any;

  @WarningsModule.Action("del")
  public deleteWarningMessagesAction: any;

  public fields = [
    {
      key: "createdOn",
      label: "Datum",
      sortable: false,
    },
    {
      key: "name",
      label: "Betrifft",
      sortable: false,
    },
    {
      key: "message",
      label: "Grund",
      sortable: false,
    },
    {
      key: "action",
      label: "",
      sortable: false,
    },
    {
      key: "studentEducationId",
      label: "",
      sortable: false,
    },
  ];

  public deleteWarningModalId = "deleteWarningModalId";
  public currentId = 0;
  public tIns = "TECHNICAL_INSPECTION";

  public mounted() {
    const filter: any = this.$store.getters["student/localFilter/getFilter"] || {};
    this.$store.commit(
      "student/localFilter/SET_LOCAL_FILTER",
      {
        ...filter,
        searchTerm: "",
      },
      { root: true }
    );
    // this.$root.$emit("keep-search-field");
  }

  public async onServicesRedirect(item: any) {
    const { studentId, name, objectId, notificationType } = item;
    this.$store.commit("student/searchLocalFilter/SET_LOCAL_FILTER", name.split(" ")[1] || "");
    if (notificationType !== this.tIns) {
      await this.$router.push({
        name: "Students",
        params: { id: studentId },
      });
    } else {
      await this.$router.push({
        name: "Vehicles",
        params: { id: objectId },
      });
    }
    this.$root.$emit("keep-search-field");
  }

  public onOpenDeleteConfirmModal(id: any) {
    //open modal
    this.$bvModal.show(this.deleteWarningModalId);
    this.currentId = id;
  }

  public async triggerDeleteWarning() {
    await this.deleteWarningMessagesAction({
      resource: "student-notifications",
      id: this.currentId,
    });
    this.$emit("delete-completed");
  }

  public cancelDeleteWarning() {
    console.log("CANCEL delete warning");
  }

  public requestQuery(query: any) {
    this.$emit("request-query", query);
  }
}
